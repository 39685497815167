@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.max-h-35-rem {
  max-height: 35rem;
}


.shadow-top {
  box-shadow: 0 -5px 3px -3px #00000030;
}

.shadow-right {
  box-shadow: 5px 0 3px -3px #00000030;
}

.shadow-bottom {
  box-shadow: 0 5px 3px -3px #00000030;
}

.shadow-left {
  box-shadow: -5px 0 3px -3px #00000030;
}
.shadow-trl {
  box-shadow: -5px 0 3px -3px #00000030, 5px 0 3px -3px #00000030, 0 -5px 3px -3px #00000030;
}

.box {
  width: 20px;
  height: 24px;
  display: flex;
}

.text-custom1 {
  color: #1c1f2b;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: rgb(86, 103, 119);
}

.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
}

.text-divider::before,
.text-divider::after {
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}


.cartCount::before {
  position: absolute;
  content: var(--cartCount);
  top: 12px;
  right: -3;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 0px 5px;
  background-color: #ff0000;
  border-radius: 50%;

}

.ncartCount:hover::before {
  transform: translate(0%, -85%);
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.sidebar-open {
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.sidebar-closed {
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
